import { useEffect, useState } from "react";
import styles from "../styles/Home.module.scss";
import { gsap } from "gsap";
import SplitType from "split-type";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import Link from "next/link";
import axios from "axios";
import Head from "next/head";
import useMobileDetect from '../utils/useMobileDetect'

import {
    Header,
    Hero,
    HorizontalSlider,
    Partners,
    Footer,
    BlogCard,
    HowItWorksSlider,
} from "../components/index";
import { ginger } from "../styles/fonts";

import dynamic from 'next/dynamic';
const Lottie = dynamic(() => import('react-lottie-player'), { ssr: false });
import { gif1, gif2, gif3, gif4 } from "../public/assets/Benefits/index";
import Image from "next/image";
import { moEngageEvents } from "../constants/moEngageEvents";
import { utmCampaignKey, utmMediumKey, utmSourceKey } from "../constants/constants";
import { getSessionStorage } from "../utils/helperMethods";

const postData = [
    {
        title: "Forget to take Diabetes medicines – Here’s what you can do?",
        tag: "THEME",
        date: "September 19, 2022",
        id: 1,
    },
    {
        title: "Forget to take Diabetes medicines – Here’s what you can do?",
        tag: "THEME",
        date: "September 19, 2022",
        id: 2,
    },
    {
        title: "Forget to take Diabetes medicines – Here’s what you can do?",
        tag: "THEME",
        date: "September 19, 2022",
        id: 3,
    },
];

const videoLinks = [
    {
        id: 2,
        title: "PillUp Customer Story | Dr. Aruna Broota",
        linkId: "fymHY2CRsD8",
        name: "Aruna",
        age: "60",
    },
    {
        id: 2,
        title: "PillUp Customer Story | Sanjana",
        linkId: "m7Jcg6pkjIw",
        name: "Sanjana",
        age: "35",
    },
    {
        id: 3,
        title: "PillUp Customer Story | Fawzan",
        linkId: "VrFLwZfic7o",
        name: "Fawzan",
        age: "29",
    },
    {
        id: 4,
        title: "PillUp Customer Story | Sarthak",
        linkId: "z8i4ayMzXIg",
        name: "Sarthak",
        age: "26",
    },
];

export default function Home() {
    const [video, setVideo] = useState("fymHY2CRsD8");
    const [counterOn, setCounterOn] = useState(false);
    const [latestPostData, setLatestPostdata] = useState([]);
    const currentDevice = useMobileDetect();

    useEffect(() => {
        window.Moengage.track_event(moEngageEvents.HOMEPAGE_LANDING, {
            utm_source: getSessionStorage(utmSourceKey),
            utm_campaign: getSessionStorage(utmCampaignKey),
            utm_medium: getSessionStorage(utmMediumKey),
            Source: currentDevice.isMobile() ? "mweb" : "web",
        });
    }, []);

    const handleVideoClick = (link) => {
        setVideo(link);
    };

    useEffect(() => {
        gsap.from("#sachet1", {
            opacity: 0,
            rotate: 0,
            y: 50,
            x: "100%",
            scrollTrigger: {
                trigger: "#horiSlider",
                start: 700,
            },
        });

        gsap.from("#sachet3", {
            opacity: 0,
            rotate: 0,
            y: 50,
            x: "-100%",
            scrollTrigger: {
                trigger: "#horiSlider",
                start: 700,
            },
        });

        gsap.from("#smileIcon", {
            opacity: 0,
            duration: 1,
            scrollTrigger: {
                trigger: "#horiSlider",
                start: 700,
            },
        });

        const joinTodayHeading = new SplitType("#joinTodayHeading", {
            types: "words, chars",
        });
        const joinTodayP = new SplitType("#joinTodayP", {
            types: "words, chars",
        });

        gsap.from(joinTodayHeading.chars, {
            opacity: 0,
            duration: 0.5,
            y: 30,
            stagger: { amount: 0.2 },
            scrollTrigger: {
                trigger: "#footer",
            },
        });
        gsap.from(joinTodayP.chars, {
            opacity: 0,
            delay: 0.3,
            y: 30,
            stagger: { amount: 0.2 },
            scrollTrigger: {
                trigger: "#footer",
            },
        });
        gsap.from("#joinTodaybtn", {
            opacity: 0,
            delay: 0.6,
            scrollTrigger: {
                trigger: "#footer",
            },
        });
    }, []);

    useEffect(() => {
        gsap.from(".benefitCard", {
            height: 0,
            padding: 0,
            duration: 0.5,
            scrollTrigger: {
                trigger: "#testimonial",
            },
        });
        gsap.from(".benefitContent", {
            borderRadius: 0,
            opacity: 0,
            delay: 0.5,
            scrollTrigger: {
                // trigger: "#joinTodayHeading",
                trigger: "#testimonial",
            },
        });
    }, []);

    useEffect(() => {
        const lastestBlogData = async () => {
            try {
                const response = await axios.get(
                    "https://blogcms.pillupreminder.com/api/articles?sort=createdAt:desc&pagination[limit]=3&populate=category&populate=cover"
                );
                await setLatestPostdata(response.data.data);
            } catch (error) {
                console.error("Error fetching data:", error);
                return null;
            }
        };
        lastestBlogData();
    }, []);

    useEffect(() => {
        const setWindowWidth = () => {
            let width = window.innerWidth;

            if (width > 770) {
                gsap.from("#rightPart", {
                    width: 0,
                    duration: 0.6,
                    scrollTrigger: {
                        trigger: "#partners",
                    },
                });
                gsap.from("#img", {
                    width: 0,
                    duration: 0.9,
                    delay: 0.6,
                    scrollTrigger: {
                        trigger: "#partners",
                    },
                });
                gsap.from("#countHeading", {
                    opacity: 0,
                    duration: 0.2,
                    delay: 1,
                    scrollTrigger: {
                        trigger: "#partners",
                    },
                });
                gsap.from("#infoContent", {
                    opacity: 0,
                    duration: 0.2,
                    delay: 1.2,
                    scrollTrigger: {
                        trigger: "#partners",
                    },
                });
            }
        };
        setWindowWidth();
    }, []);

    return (
        <>
            <Head>
                <meta name="title" content="PillUp - Get Pre-Sorted Medications At Your Door Step along with medicine reminders." />
                <meta name="description"
                    content="PillUp is an online pharmacy that sorts your medications into easy to tear pouches by time and date and delivers them to your door. Each pouch comes with date, time, and medications printed right on the front. Combining convenient packaging, modern technology, and personalisation for every customer, PillUp becomes the most customer centric pharmacy." />
            </Head>

            <div className={styles.home}>
                {/* <Header fontBlack={true} /> */}
                <Hero />
                {/* *******************Features Section******************************* */}
                <div className={styles.feature}>
                    <div className={styles.mainContent}>
                        <img
                            className={styles.smile}
                            src={"/assets/smileIcon.svg"}
                            width={70}
                            id="smileIcon"
                            alt="next"
                        />
                        <h2>
                            Free dose wise pouches,
                            <br /> medicine reminders and delivery
                            <br /> for First three months, you only <br /> pay for your
                            medicines.
                        </h2>
                        <p style={ginger.style}>Only Rs.99/ month afterwards.</p>
                    </div>

                    <div className={styles.sachets}>
                        <img
                            src={"/assets/sachet 1.png"}
                            className={styles.sachet1}
                            id="sachet1"
                            width={370}
                            alt=""
                        />
                        <img
                            src={"/assets/sachet 2.png"}
                            className={styles.sachet2}
                            id="sachet2"
                            width={370}
                            alt=""
                        />
                        <img
                            src={"/assets/sachet 3.png"}
                            id="sachet3"
                            className={styles.sachet3}
                            width={370}
                            alt=""
                        />
                    </div>
                </div>
                {/* ****************************************************************** */}
                {/* *******************Horizontal Slider******************************* */}
                <div id="horiSlider">
                    <HorizontalSlider />
                </div>
                {/* ****************************************************************** */}
                {/* *******************Quote Section******************************* */}
                <div id="QuoteSection" className={styles.QuoteSection}>
                    <div className={styles.quoteLeft}>
                        <img
                            className={styles.quoteElipseU}
                            src={"/assets/quoteElement1.svg"}
                            id="quoteElement"
                            alt="next"
                        />
                        <div className={styles.quoteLeftImgContainer}>
                            <img src="/assets/quoteImg.png"></img>
                        </div>
                    </div>
                    <div className={styles.quoteRight}>
                        <div className={styles.quotLeftContent} style={ginger.style}>
                            <h2>“Ready to try PillUp</h2>
                            <h2 className={styles.lowerHeading}>for free?”</h2>
                            <a href="tel:+91 9818360666">
                                <button className={styles.quoteLeftrequestBtn}>
                                    Request Call
                                </button>
                            </a>
                        </div>
                        <img
                            className={styles.quoteElipseL}
                            src={"/assets/quoteElement2.svg"}
                            id="quoteElement"
                            alt="next"
                        />
                    </div>
                </div>
                {/* ****************************************************************** */}
                <div>
                    <HowItWorksSlider />
                </div>
                {/* *******************Achievements Section******************************* */}{" "}
                <div id="achievements" className={styles.achievContainer}>
                    <h1 className={styles.achievinfoContent}>
                        We are{" "}
                        <span style={{ fontStyle: "italic", fontWeight: "600" }}>
                            enabling thousands of people to live longer and better
                        </span>{" "}
                        , one pouch at a time.
                    </h1>
                    <ScrollTrigger
                        onEnter={() => setCounterOn(true)}
                        onExit={() => setCounterOn(false)}
                    >
                        <div className={styles.achiev}>
                            <div className={styles.capsule}>
                                <img
                                    src="/assets/achiev1.svg"
                                    id="img"
                                    className={styles.imgComponent}
                                />
                                <h2 id="countHeading" style={ginger.style}>
                                    {counterOn ? (
                                        <div style={{ display: "flex" }}>
                                            <div>
                                                <CountUp start={0} end={80} delay={0}>
                                                    {({ countUpRef }) => (
                                                        <div>
                                                            <span ref={countUpRef} />
                                                        </div>
                                                    )}
                                                </CountUp>
                                            </div>
                                            <span>%</span>
                                        </div>
                                    ) : (
                                        "80%"
                                    )}
                                </h2>
                                <p id="infoContent">Started taking medicines on time</p>
                            </div>
                            <div className={styles.capsule}>
                                <img
                                    src="/assets/accurate-icon.svg"
                                    id="img"
                                    className={styles.imgComponent}
                                />
                                <h2 id="countHeading" style={ginger.style}>
                                    {counterOn ? (
                                        <div style={{ display: "flex" }}>
                                            <div>
                                                <CountUp start={50} end={0} delay={0}>
                                                    {({ countUpRef }) => (
                                                        <div>
                                                            <span ref={countUpRef} />
                                                        </div>
                                                    )}
                                                </CountUp>
                                            </div>
                                            <span>%</span>
                                        </div>
                                    ) : (
                                        "~0%"
                                    )}
                                </h2>
                                <p id="infoContent">Error in taking medicines</p>
                            </div>
                            <div className={styles.capsule}>
                                <img
                                    src="/assets/achiev2.svg"
                                    id="img"
                                    className={styles.imgComponent}
                                />
                                <h2 id="countHeading" style={ginger.style}>
                                    {counterOn ? (
                                        <div style={{ display: "flex" }}>
                                            <span>+</span>
                                            <div>
                                                <CountUp start={0} end={100000} delay={0}>
                                                    {({ countUpRef }) => (
                                                        <div>
                                                            <span ref={countUpRef} />
                                                        </div>
                                                    )}
                                                </CountUp>
                                            </div>
                                        </div>
                                    ) : (
                                        "+100000"
                                    )}
                                </h2>
                                <p id="infoContent">Pouches packed</p>
                            </div>
                            <div className={styles.capsule}>
                                <img
                                    src="/assets/achiev3.svg"
                                    id="img"
                                    className={styles.imgComponent}
                                />
                                <h2 id="countHeading" style={ginger.style}>
                                    {counterOn ? (
                                        <div style={{ display: "flex" }}>
                                            <span>+</span>
                                            <div>
                                                <CountUp start={0} end={1000} delay={0}>
                                                    {({ countUpRef }) => (
                                                        <div>
                                                            <span ref={countUpRef} />
                                                        </div>
                                                    )}
                                                </CountUp>
                                            </div>
                                        </div>
                                    ) : (
                                        "+1000"
                                    )}
                                </h2>
                                <p id="infoContent">Happy Customers</p>
                            </div>
                        </div>
                    </ScrollTrigger>
                </div>
                {/* ****************************************************************** */}
                {/* *******************Partners Section******************************* */}
                <div className="partners" id="partners">
                    <Partners />
                </div>
                {/* ****************************************************************** */}
                {/* *******************Benefits Section******************************* */}
                <div className={styles.benefitsSection} id="benefitSection">
                    <div className={styles.upperElipse} id="upper_elipse"></div>
                    <div className={styles.benefitsHeadingContent}>
                        <h3 style={ginger.style}>
                            Why PillUp is needed in every Indian house
                        </h3>
                    </div>
                    <div className={styles.cardContainer}>
                        <div className={styles.upperPart}>
                            <Link
                                href="/WhyUs/#benefitsPoint"
                                style={{ textDecoration: "none", color: "black" }}
                            >
                                <div className={`benefitCard ${styles.benefitCardModule}`}>
                                    <div
                                        style={{
                                            width: 70,
                                            height: 70,
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            overflow: "hidden",
                                        }}
                                    >
                                        <Lottie
                                            loop
                                            animationData={gif1}
                                            play
                                            style={{
                                                width: 200,
                                                height: 200,
                                                position: "absolute",
                                            }}
                                        />
                                    </div>
                                    <h3 className="benefitContent" style={ginger.style}>
                                        Automatically refill your next dose
                                    </h3>
                                </div>
                            </Link>
                            <Link
                                href="/WhyUs/#benefitsPoint"
                                style={{ textDecoration: "none", color: "black" }}
                            >
                                <div className={`benefitCard ${styles.benefitCardModule}`}>
                                    <Lottie
                                        loop
                                        animationData={gif2}
                                        play
                                        style={{
                                            width: 60,
                                            height: 60,
                                        }}
                                    />
                                    <h3 className="benefitContent" style={ginger.style}>
                                        Helps elders become more independent
                                    </h3>
                                </div>
                            </Link>
                            <Link
                                href="/WhyUs/#benefitsPoint"
                                style={{ textDecoration: "none", color: "black" }}
                            >
                                <div className={`benefitCard ${styles.benefitCardModule}`}>
                                    <Lottie
                                        loop
                                        animationData={gif3}
                                        play
                                        style={{
                                            width: 70,
                                            height: 70,
                                        }}
                                    />
                                    <h3 className="benefitContent" style={ginger.style}>
                                        Ensures medicines are taken on time
                                    </h3>
                                </div>
                            </Link>
                        </div>
                        <div className={styles.lowerPart}>
                            <img
                                src={"/assets/Benefits/bgElement.svg"}
                                className={styles.leftElement}
                            ></img>
                            <img
                                src={"/assets/Benefits/bgElement.svg"}
                                className={styles.rightElement}
                            ></img>{" "}
                            <Link
                                href="/WhyUs/#benefitsPoint"
                                style={{ textDecoration: "none", color: "black" }}
                            >
                                <div className={`benefitCard ${styles.benefitCardModule}`}>
                                    <Lottie
                                        loop
                                        animationData={gif4}
                                        play
                                        style={{
                                            width: 70,
                                            height: 70,
                                        }}
                                    />

                                    <h3 className="benefitContent" style={ginger.style}>
                                        Offer discounts on order & delivery
                                    </h3>
                                </div>
                            </Link>
                            <Link
                                href="/WhyUs/#benefitsPoint"
                                style={{ textDecoration: "none", color: "black" }}
                            >
                                <div className={`benefitCard ${styles.benefitCardModule}`}>
                                    <Lottie
                                        loop
                                        animationData={gif3}
                                        play
                                        style={{
                                            width: 70,
                                            height: 70,
                                        }}
                                    />
                                    <h3 className="benefitContent" style={ginger.style}>
                                        Avoids over or under dosage of medicines
                                    </h3>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <img
                        className={styles.quoteElipseU}
                        src={"/assets/benefitElement2.svg"}
                        id="quoteElement"
                        alt="next"
                    />
                </div>
                {/* ****************************************************************** */}
                {/* *******************Testimonial Section******************************* */}
                <div id="testimonial" className={styles.testimonial}>
                    <h2>People are loving PillUp</h2>
                    <div className={styles.videoContainer}>
                        <div
                            className={styles.videoBox}
                            style={{ filter: "drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.27))" }}
                        >
                            <iframe
                                width="100%"
                                height="100%"
                                src={`https://www.youtube.com/embed/${video}`}
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen
                            ></iframe>
                        </div>
                        <div className={styles.videoListContainer}>
                            {videoLinks
                                .filter((obj) => obj.linkId !== video)
                                .map(({ name, id, title, age, linkId }) => {
                                    return (
                                        <div
                                            className={styles.videoItem}
                                            onClick={() => handleVideoClick(linkId)}
                                            key={id}
                                        >
                                            <div className={styles.videoThumbnail}>
                                                <img
                                                    src={`https://img.youtube.com/vi/${linkId}/sddefault.jpg`}
                                                    // width="100%"
                                                    height={"135%"}
                                                ></img>
                                            </div>
                                            <div>
                                                <h3 style={ginger.style}>{title}</h3>
                                                <p>
                                                    {age}, {name}
                                                </p>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                    <img
                        className={styles.testimonialLowerElipse}
                        src={"/assets/videoElement1.svg"}
                        id="quoteElement"
                        alt="next"
                    />
                </div>
                {/* ****************************************************************** */}
                {/* *******************Blog Section*************************** */}
                <div className={styles.blogSection}>
                    <h1 style={ginger.style}>Our Latest Blogs</h1>

                    <Link
                        href="/blogs"
                        className={styles.blogLink}
                    // style={{
                    //   textDecoration: "none",
                    //   fontSize: "1.2rem",
                    //   color: "#06386C",
                    // }}
                    >
                        More
                    </Link>

                    <div className={styles.blogContainer}>
                        {latestPostData
                            ? latestPostData.map(({ id, attributes }) => {
                                return <BlogCard data={attributes} key={id} id={id} />;
                            })
                            : "1"}
                    </div>
                </div>
                {/* ****************************************************************** */}
                {/* *******************Pharmacy all you need*************************** */}
                <div className={styles.joinToday}>
                    <h1 id="joinTodayHeading" style={ginger.style}>
                        Your online pharmacy that sorts, packs and delivers all your medicines
                        & supplements.
                    </h1>
                    <p id="joinTodayP">Try for free : {")"}</p>
                    <a
                        href="https://wa.me/+919266607026?text=Hi%20I%20am%20interested%20in%20ordering%20from%20PillUp"
                        rel="noreferrer"
                        target="_blank"
                    >
                        <button id="joinTodaybtn" className={styles.joinTodaybtn}>
                            Join Today
                        </button>
                    </a>
                </div>
                {/* ****************************************************************** */}
                {/* *******************Footer Section********************************* */}
                <div id="footer">
                    <Footer />
                </div>
                {/* ****************************************************************** */}
            </div>
        </>
    );
}
